@import url('https://fonts.googleapis.com/css?family=Comfortaa:300,400,500,600,700&display=swap');

body {
  margin: 0;
  font-family: 'Comfortaa', cursive;
  font-weight: 100;
  background-color: #000;
  color: #fff;
}

button, input, select {
  font-family: 'Comfortaa', cursive;
}