.SearchForm {
  margin: 2rem;
  text-align: center;
}

.SearchForm-input {
  border: 2px solid #ffb619;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  font-size: 1.2rem;
  padding: .5rem;
  text-align: center;
}