.Qoqtail {
  display: flex;
  flex-direction: column;
  border: 3px solid #ffb619;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #000;
  color: #fff;
  max-width: 300px;
}

.Qoqtail-title {
  text-align: center;
  margin: 0;
  font-size: 1.5rem;
}

.Qoqtail-image {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.Qoqtail-image img {
  width: 150px;
  height: 150px;
  border: 1px solid #fff;
  border-radius: 3px;
}

.Qoqtail-ingredient-container{
  border-bottom: 1px solid #fff;
  padding-top: .5rem;
}
.Qoqtail-ingredient, .Qoqtail-measure {
  display: inline-block;
  width: 50%;
}

.Qoqtail-glass {
  margin-top: 2rem;
  text-align: center;
}

.Qoqtail-button {
  display: table;
  margin: 1rem auto;
  background-color: #ffb619;
  font-size: 1.5rem;
  padding: .5rem 1rem;
  border: 1px solid #fff;
  border-radius: 3px;
}

@media (min-width: 700px) {
  .Qoqtail {
    max-width: 350px;
  }
}

@media (min-width: 900px) {
  .Qoqtail {
    max-width: 400px;
  }
}