/* @import url('https://fonts.googleapis.com/css?family=Righteous&display=swap'); */

.Navigation {
  display: flex;
  background-color: #ffb619;
  padding: 1rem .2rem;
  align-items: center;
}

.Navigation-brand {
  margin: 0;
  margin-left: .5rem;
  /* font-family: 'Righteous', cursive; */
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
  text-decoration: underline;
}

.Navigation-buttons {
  margin-left: auto;
  align-self: stretch;
  display: flex;
  align-items: center;
}

.Navigation-button {
  padding: .6rem .3rem;
  margin: 0 .3rem;
  color: #fff;
  background-color: #000;
  border: 0;
  border-radius: 7px;
}

@media (min-width: 420px) {
  .Navigation-brand {
    margin-left: 1rem;
    font-size: 2rem;
  }
  .Navigation-button {
    padding: 1rem .5rem;
    margin: 0 .8rem;
    font-size: .9rem;
  }
}