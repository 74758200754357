.Signin {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.Signin-title {
  margin: 2rem 0;
}

.Signin-form {
  text-align: center;
}

.Signin-input-container {
  margin: 2rem 0;
}

.Signin-input {
  border: 2px solid #ffb619;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  text-align: center;
  font-size: 1.2rem;
}

.Signin-error {
  color: #ffb619;
  font-weight: 700;
}

.Signin-changeroute {
  cursor: pointer;
}

.Signin-submit {
  font-size: 1rem;
  background-color: #ffb619;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: .5rem;
}