@import url(https://fonts.googleapis.com/css?family=Comfortaa:300,400,500,600,700&display=swap);
body {
  margin: 0;
  font-family: 'Comfortaa', cursive;
  font-weight: 100;
  background-color: #000;
  color: #fff;
}

button, input, select {
  font-family: 'Comfortaa', cursive;
}
/* @import url('https://fonts.googleapis.com/css?family=Righteous&display=swap'); */

.Navigation {
  display: flex;
  background-color: #ffb619;
  padding: 1rem .2rem;
  align-items: center;
}

.Navigation-brand {
  margin: 0;
  margin-left: .5rem;
  /* font-family: 'Righteous', cursive; */
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
  text-decoration: underline;
}

.Navigation-buttons {
  margin-left: auto;
  align-self: stretch;
  display: flex;
  align-items: center;
}

.Navigation-button {
  padding: .6rem .3rem;
  margin: 0 .3rem;
  color: #fff;
  background-color: #000;
  border: 0;
  border-radius: 7px;
}

@media (min-width: 420px) {
  .Navigation-brand {
    margin-left: 1rem;
    font-size: 2rem;
  }
  .Navigation-button {
    padding: 1rem .5rem;
    margin: 0 .8rem;
    font-size: .9rem;
  }
}
.Signin {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.Signin-title {
  margin: 2rem 0;
}

.Signin-form {
  text-align: center;
}

.Signin-input-container {
  margin: 2rem 0;
}

.Signin-input {
  border: 2px solid #ffb619;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  text-align: center;
  font-size: 1.2rem;
}

.Signin-error {
  color: #ffb619;
  font-weight: 700;
}

.Signin-changeroute {
  cursor: pointer;
}

.Signin-submit {
  font-size: 1rem;
  background-color: #ffb619;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: .5rem;
}
.SearchForm {
  margin: 2rem;
  text-align: center;
}

.SearchForm-input {
  border: 2px solid #ffb619;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  font-size: 1.2rem;
  padding: .5rem;
  text-align: center;
}
.Qoqtail {
  display: flex;
  flex-direction: column;
  border: 3px solid #ffb619;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #000;
  color: #fff;
  max-width: 300px;
}

.Qoqtail-title {
  text-align: center;
  margin: 0;
  font-size: 1.5rem;
}

.Qoqtail-image {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.Qoqtail-image img {
  width: 150px;
  height: 150px;
  border: 1px solid #fff;
  border-radius: 3px;
}

.Qoqtail-ingredient-container{
  border-bottom: 1px solid #fff;
  padding-top: .5rem;
}
.Qoqtail-ingredient, .Qoqtail-measure {
  display: inline-block;
  width: 50%;
}

.Qoqtail-glass {
  margin-top: 2rem;
  text-align: center;
}

.Qoqtail-button {
  display: table;
  margin: 1rem auto;
  background-color: #ffb619;
  font-size: 1.5rem;
  padding: .5rem 1rem;
  border: 1px solid #fff;
  border-radius: 3px;
}

@media (min-width: 700px) {
  .Qoqtail {
    max-width: 350px;
  }
}

@media (min-width: 900px) {
  .Qoqtail {
    max-width: 400px;
  }
}
.ListResults {
  margin: 1rem;
}

.ListResults-error {
  text-align: center;
  color: #ffb619;
}
.MyQoqtails {
  margin: 1rem;
}

.MyQoqtails-select {
  display: table;
  margin: 1.5rem auto;
  padding: .5rem 1rem;
  background-color: #fff;
  font-size: 1.2rem;
  border: 2px solid #ffb619;
  border-radius: 5px;
}

.MyQoqtails-error {
  text-align: center;
  color: #ffb619;
  margin-top: 4rem;
}
.Footer {
  margin: 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5rem;
}

.Footer-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.Footer img, .Footer a {
  vertical-align: middle;
  height: 2.5rem;
}
.page-container {
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: 5rem;
}

.back-to-top {
  margin-bottom: 5rem !important;
}
