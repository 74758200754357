.Footer {
  margin: 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5rem;
}

.Footer-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.Footer img, .Footer a {
  vertical-align: middle;
  height: 2.5rem;
}